var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Router } from "@uuip/unified-ui-platform";
import { STORE } from "@/store";
import { html } from "lit-html";
import { LitElement, customElement } from "lit-element";
import style from "./QueueStatsDetailsBlock.scss";
import config from "../config-queue-stats.json";
/**
 * @element agentx-queue-stats-widgets
 */
let AgentQueueStats = class AgentQueueStats extends LitElement {
    static get styles() {
        return style;
    }
    render() {
        return html `
      <dynamic-area .area=${config} .isStationLoginSuccess=${STORE.app.isStationLoginSuccess}></dynamic-area>
    `;
    }
};
AgentQueueStats = __decorate([
    customElement("agentx-queue-stats-widgets")
], AgentQueueStats);
export { AgentQueueStats };
const QueueStatsDetailsBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <div class="queue-detail">
        <div class="frame">
          <md-button
            @click="${() => {
            STORE.layout.setRouterViewClasses({ expanded: false });
        }}"
            >Close</md-button
          >
          <agentx-wc-queue-stats-header></agentx-wc-queue-stats-header>
          <div class="queue-detail-box">
            <agentx-wc-queue-stats-detail queueId="${STORE.routing.currentRouteValues.params.queueId}">
              <agentx-queue-stats-widgets slot="widget-first-row"></agentx-queue-stats-widgets>
            </agentx-wc-queue-stats-detail>
          </div>
          <div class="queue-detail-box">
            <agentx-wc-queue-performance-details-view></agentx-wc-queue-performance-details-view>
          </div>
        </div>
      </div>
    `,
        styles: style
    });
};
export default QueueStatsDetailsBlock;
