import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import { Router } from "@uuip/unified-ui-platform";
import { html } from "lit-element";
import { BaseView } from "../_base-view/BaseView";
import { interactionsViewContext as context } from "./interactions-view-context";
import styles from "./interactions-view.scss";
const InteractionsView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: {
            interactions: new Router.RouterView.Block({
                template: html `
          <div class="interactions-view">
            <div class="frame">
              <agentx-wc-interactions-tab-container
                ?isDarkMode=${STORE.app.darkMode}
                .selectedTab=${context.selectedTab}
                @action-button-open-details=${(e) => {
                    STORE.layout.setRouterViewClasses({ expanded: true });
                    context.updateInteractionId(e.detail.eventId);
                }}
                @interactions-tab-selected-tab-changed=${(e) => {
                    context.updateSelectedTab(e.detail.selectedTab);
                }}
              ></agentx-wc-interactions-tab-container>
            </div>
          </div>
        `
            }),
            details: new Router.RouterView.Block({
                template: html `
          <div class="details-view">
            <div class="frame">
              <p>Details view</p>
              <p>Interaction ID: ${context.interactionId}</p>
              <md-button
                @click="${() => {
                    STORE.layout.setRouterViewClasses({ expanded: false });
                }}"
                >Close</md-button
              >
            </div>
          </div>
        `
            })
        },
        styles
    });
};
export default InteractionsView;
