import "@agentx/agentx-mfe-wc-based";
import { Router } from "@uuip/unified-ui-platform";
import { BaseView } from "../_base-view/BaseView";
import style from "./QueueStatsView.scss";
import QueueStatsBlock from "../queue-stats-view/blocks/QueueStatsBlock";
import QueueStatsDetailsBlock from "../queue-stats-view/blocks/QueueStatsDetailsBlock";
const QueueStatsView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: {
            queue: QueueStatsBlock(),
            details: QueueStatsDetailsBlock()
        },
        styles: style
    });
};
export default QueueStatsView;
