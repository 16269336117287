var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CONTACT_DIRECTION, OUTBOUND_TYPE } from "@/app/components/constants";
import { t } from "@/mixins/i18nMixin";
import { MediaType } from "@/store/store-agent-contact";
import { createBrowserNotifications } from "@uuip/unified-ui-platform-sdk";
import { action, observable } from "mobx";
import { DESKTOP_NOTIFICATION_SOUND_SETTINGS, SOUND_DEFAULT_SETTINGS } from "./shared-actions/constants";
const MILLISECONDS_IN_SECOND = 1000;
export class ModuleBrowserNotifications {
    constructor(notifications) {
        this.isTabFocus = true;
        this.browserNotificationAutoDismiss = 8000; //ms
        this.popoverAudioObject = new Audio("/sounds/new_contact_sound.mp3");
        this.ringAudioObject = new Audio("/sounds/ringtone.mp3");
        this.getOutboundNotificationBody = (data) => {
            switch (data.outboundType) {
                case OUTBOUND_TYPE.CAMPAIGN:
                    return t("app:browserNotifications.campaignOutboundCallRequest");
                case OUTBOUND_TYPE.OUTDIAL_RESERVATION:
                    return t("app:browserNotifications.outdialReservationCallRequest");
                case OUTBOUND_TYPE.IVR_PREDICTIVE:
                case OUTBOUND_TYPE.IVR_PROGRESSIVE:
                    return t("app:browserNotifications.IVRCallRequest");
                default:
                    return t("app:browserNotifications.newOutboundCallRequest");
            }
        };
        this.getBrowserNotificationBody = (data) => {
            switch (data.type) {
                case MediaType.Telephony:
                    return data.contactDirection === CONTACT_DIRECTION.OUTBOUND || data.outboundType
                        ? this.getOutboundNotificationBody(data)
                        : t("app:browserNotifications.newIncomingCallRequest");
                case MediaType.Chat:
                    return t("app:browserNotifications.newChatRequest");
                case MediaType.Email:
                    return t("app:browserNotifications.newEmailRequest");
                case MediaType.Social:
                    return t("app:browserNotifications.newSocialRequest");
                default:
                    return "New request";
            }
        };
        this.notifications = notifications;
        this.requestPermission();
        this.loadSound(); //loading sound
    }
    requestPermission() {
        return __awaiter(this, void 0, void 0, function* () {
            // invoke isPermissionGranted only if browser notification is supported  else it throws undefined error
            if (this.notifications &&
                this.notifications.isBrowserNotificationSupported &&
                !this.notifications.isPermissionGranted) {
                yield this.notifications.requestNotificationUserPermission();
            }
        });
    }
    setIsTabFocus(isFocus) {
        this.isTabFocus = isFocus;
    }
    playSound(isWebCall = false) {
        // isWebCall will be true only if the call is a web call and the feature flag CX-13578-webrtc-ringtone is enabled
        // if FF CX-13578-webrtc-ringtone is on :
        //    1. plays ringtone if the call is a web call
        //    2. plays default chime sound if the call is not a web call
        //    3. plays default chime sound for all other media types
        // if FF CX-13578-webrtc-ringtone is off :
        //    plays default chime sound for all media types
        if (isWebCall) {
            // updating the sound settings for web call
            this.updateWebCallAudioSettings();
            this.playRing();
        }
        else {
            // updating the sound settings for default chime sound
            this.updateAudioSettings();
            this.popoverAudioObject.play();
        }
    }
    loadSound() {
        // load the sound settings
        this.popoverAudioObject.load();
        this.ringAudioObject.load();
        this.ringAudioObject.loop = true;
        this.updateAudioSettings();
        this.updateWebCallAudioSettings();
    }
    playRing() {
        //play ringtone
        this.ringAudioObject.play();
    }
    stopRing() {
        // stop the ringtone and reset the time to 0
        // This is used when the user :  accepts the call / rejects the call / the call is RONA
        this.ringAudioObject.pause();
        this.ringAudioObject.currentTime = 0;
    }
    getAudioSettings() {
        // get the sound settings from local storage
        const notificationSettings = JSON.parse(localStorage.getItem(DESKTOP_NOTIFICATION_SOUND_SETTINGS) || SOUND_DEFAULT_SETTINGS);
        const agentId = sessionStorage.getItem("agentId") || "default";
        return notificationSettings[agentId] || JSON.parse(SOUND_DEFAULT_SETTINGS).default;
    }
    setAudioProperties(audioObject) {
        // set the sound settings to the audio object passed
        const notification = this.getAudioSettings();
        audioObject.volume = notification.volume;
        audioObject.muted = notification.mute || !notification.sound;
        audioObject.pause();
        audioObject.currentTime = 0;
    }
    updateAudioSettings() {
        // update the sound settings for the default chime sound
        this.setAudioProperties(this.popoverAudioObject);
    }
    updateWebCallAudioSettings() {
        // update the sound settings for the web call ringtone
        this.setAudioProperties(this.ringAudioObject);
    }
    addOnClick(notif) {
        if (notif) {
            notif.onclick = function () {
                window.focus();
                notif.close();
            };
        }
    }
    setNotificationTimeout(seconds) {
        this.browserNotificationAutoDismiss = seconds * MILLISECONDS_IN_SECOND;
    }
    fireNewOfferNotification(task) {
        if (this.notifications.isPermissionGranted && !this.isTabFocus) {
            const title = "Webex CC Desktop";
            let message = `${this.getBrowserNotificationBody(task)}`;
            if (task.outboundType !== OUTBOUND_TYPE.OUTDIAL_RESERVATION) {
                message += ` ${task.header.contactTitle}`;
            }
            const notif = this.notifications.fire(title, {
                body: message,
                tag: task.id
            });
            this.addOnClick(notif);
            /** Configurable notification timeout through layout JSON */
            setTimeout(() => {
                if (notif) {
                    notif.close();
                }
            }, this.browserNotificationAutoDismiss);
        }
    }
    firebrowserNotification(message, title = "Webex CC Desktop") {
        if (this.notifications.isPermissionGranted && !this.isTabFocus) {
            const notifs = this.notifications.fire(title, {
                body: message
            });
            this.addOnClick(notifs);
            /** Configurable notification timeout through layout JSON */
            setTimeout(() => {
                if (notifs) {
                    notifs.close();
                }
            }, this.browserNotificationAutoDismiss);
        }
    }
}
__decorate([
    observable
], ModuleBrowserNotifications.prototype, "isTabFocus", void 0);
__decorate([
    observable
], ModuleBrowserNotifications.prototype, "browserNotificationAutoDismiss", void 0);
__decorate([
    observable
], ModuleBrowserNotifications.prototype, "popoverAudioObject", void 0);
__decorate([
    observable
], ModuleBrowserNotifications.prototype, "ringAudioObject", void 0);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "setIsTabFocus", null);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "playSound", null);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "loadSound", null);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "stopRing", null);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "setNotificationTimeout", null);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "fireNewOfferNotification", null);
__decorate([
    action
], ModuleBrowserNotifications.prototype, "firebrowserNotification", null);
/**
 * Set up notofication host
 */
export const browserNotifications = new ModuleBrowserNotifications(createBrowserNotifications());
