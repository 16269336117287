import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { html } from "lit-html";
import style from "./QueueStatsBlock.scss";
const QueueStatsBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <div class="queues-stats">
        <div class="frame">
          <agentx-wc-queue-stats-landing
            class="queues-stats-landing"
            .isDarkMode=${STORE.app.darkMode}
            @action-button-open-details=${(e) => {
            const validQueue = STORE.agent.managedQueues.some((queue) => queue.queueId === e.detail.eventId);
            if (validQueue) {
                STORE.layout.setRouterViewClasses({ expanded: true });
                STORE.agentContact.selectedQueueStatsQueueChange(e.detail.eventId);
            }
        }}
          ></agentx-wc-queue-stats-landing>
        </div>
      </div>
    `,
        styles: [style]
    });
};
export default QueueStatsBlock;
