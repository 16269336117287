var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { Logger } from "@uuip/unified-ui-platform-sdk";
import { customElement, html, LitElement, property } from "lit-element";
import { removeUnloadEvent } from "../utils/Utils";
import style from "./ConnectionLost.scss";
let ConnectionLost = class ConnectionLost extends LitElement {
    constructor() {
        super(...arguments);
        this.languageResources = "/i18n/{{lng}}/{{ns}}.json";
        this.isRestoreFailed = false;
        this.isSessionExpired = false;
    }
    static get styles() {
        return style;
    }
    downloadLogs(event) {
        SERVICE.telemetry.timeEvent(SERVICE.telemetry.TELEM_EVENT.ERROR_DETAILS_DOWNLOADED);
        SERVICE.telemetry.track(SERVICE.telemetry.TELEM_EVENT.ERROR_DETAILS_DOWNLOADED, {
            [SERVICE.telemetry.TELEM_PROPS.ERROR_DETAILS_DOWNLOADED]: true,
            Status: SERVICE.telemetry.TELEM_EVENT.STATUS.SUCCESS
        });
        if (STORE.app.featureFlags.isUploadLogsToWebexEnabled) {
            SERVICE.webex.uploadLogs();
        }
        event.preventDefault();
        Logger.POOL.browserDownloadAllLogsText();
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (this.isRestoreFailed || this.isSessionExpired) {
            removeUnloadEvent();
        }
    }
    render() {
        return html `
      <div class="loading-screen">
        <div class="overlay-message">
          ${this.isSessionExpired
            ? html `
                <p class="connection-lost-header">${t("app:connection.warning")}</p>
                <p class="connection-lost-message">${t("app:connection.sessionRestorationFailed")}</p>
              `
            : !this.isRestoreFailed
                ? html `
                  <md-spinner size="80"></md-spinner>
                  <p class="connection-lost-message">${t("app:connection.connectionFailure")}</p>
                `
                : html `
                  <p class="connection-lost-header">${t("app:connection.connectionLostHeader")}</p>
                  <p class="connection-lost-message">${t("app:connection.connectionNotRecovered")}</p>
                `}
          <md-link class="download-logs" @click=${this.downloadLogs}> ${t("app:connection.downloadLogs")} </md-link>
        </div>
      </div>
    `;
    }
};
__decorate([
    property({ type: Boolean })
], ConnectionLost.prototype, "isRestoreFailed", void 0);
__decorate([
    property({ type: Boolean })
], ConnectionLost.prototype, "isSessionExpired", void 0);
ConnectionLost = __decorate([
    customElement("connection-lost")
], ConnectionLost);
export { ConnectionLost };
