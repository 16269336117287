var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createLogger } from "@/sdk";
import { SERVICE } from "@agentx/agentx-services";
import StackTrace from "stacktrace-js";
export const logger = createLogger("[App: Exception Tracker]");
export const exceptionTracker = (accessToken) => {
    window.onerror = function (message, source, lineno, colno, error) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!accessToken) {
                return;
            }
            let originalPosition = {
                source: undefined,
                lineno: undefined,
                colno: undefined
            };
            if (error) {
                const stackFrames = yield StackTrace.fromError(error);
                originalPosition = {
                    source: stackFrames[0].fileName,
                    lineno: stackFrames[0].lineNumber,
                    colno: stackFrames[0].columnNumber
                };
                const stackString = stackFrames.map((frame) => frame.toString()).join("\n");
                logger.error(stackString);
                StackTrace.getSync();
            }
            SERVICE.telemetry.track("Uncaught exception", {
                Message: message,
                Source: originalPosition.source || source,
                Lineno: originalPosition.lineno || lineno,
                Colno: originalPosition.colno || colno,
                Error: error,
                Threadtype: "main"
            }, [(_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.webex_business]);
        });
    };
};
